<template>
  <v-card>
    <v-card-title>Anzeige verbinden</v-card-title>
    <v-card-text>
      <v-text-field
        v-model="search"
        :label="$t('Search Displays')"
        clearable
        :clear-icon="icons.mdiCloseCircleOutline"
      ></v-text-field>

      <v-treeview
        :active.sync="active"
        :items="location_hierarchy"
        :search="search"
        :open.sync="open"
        activatable
        color="success"
        open-on-click
        transition
        item-children="childs"
      >
        <template #prepend="{ item }">
          <v-icon v-if="item.type === 'display'">
            {{ icons.mdiMonitor }}
          </v-icon>
          <v-icon v-else>
            {{ icons.mdiFolderNetworkOutline }}
          </v-icon>
        </template>
      </v-treeview>

      <v-btn
        v-if="selectedDisplayId"
        color="success"
        block
        large
        @click="callConnect"
      >
        {{ $t('Connect') }}
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMonitor, mdiFolderNetworkOutline } from '@mdi/js'
import store from '@/store'
import { mapState } from 'vuex'
import axios from '@axios'
// eslint-disable-next-line import/no-cycle
import router from '@/router'

export default {
  data() {
    return {
      active: [],
    }
  },
  computed: {
    ...mapState('app', ['location_hierarchy']),
    selectedDisplayId() {
      if (this.active.length > 0) {
        return this.active[0].replace('display-', '')
      }

      return null
    },
  },
  mounted() {
    // store.dispatch('app/fetchDisplays')
    store.dispatch('app/fetchLocationHierarchyByDisplay')
  },
  methods: {
    callConnect() {
      axios.post(`/displays/${this.selectedDisplayId}/connect`, {
        bue_ds_screen_token: this.$route.params.token,
      }).then(response => {
        if (response.data.success) {
          router.push({ name: 'apps-locations-list', params: { message: this.$t('Display successfully connected') } })
        } else {
          console.log(`Error: ${response.data.message}`)
        }
      }, error => {
        console.log(`Error: ${error}`)
      })
    },
  },
  setup() {
    const open = ref([1, 2])
    const search = ref(null)
    const caseSensitive = ref(false)
    const { token } = router.currentRoute.params

    return {
      open,
      search,
      caseSensitive,
      token,

      // icons
      icons: { mdiMonitor, mdiFolderNetworkOutline },
    }
  },
}
</script>
